/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  TextField
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(2),
    background: '#ffffff'
  },
}));

const InputText = props => {
  const { form, field } = props;
  const classes = useStyles();
  const { handleChange, isSubmitting, errors, touched } = form;
  const { name, value } = field;
  return (
    <TextField
      className={classes.textField}
      disabled={isSubmitting}
      margin="dense"
      error={!!(errors[name] && touched[name])}
      fullWidth
      helperText={
        errors[name] && touched[name] ? errors[name] : null
      }
      label={props.label}
      name={name}
      onChange={handleChange(name)}
      type={props.type ? props.type : 'text'}
      value={value || value == 0 ? `${value}` : ''}
      variant="outlined"
    />
  );
};

export default InputText;
