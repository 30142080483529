import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Button } from '@material-ui/core';
import Apple from '@material-ui/icons/Apple';
import Android from '@material-ui/icons/Android';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    textAlign: 'center'
  },
  available: {
    fontSize: '80%'
  },
  button: {
    background: '#1e4355 !important',
    color: '#ffffff !important',
    margin: theme.spacing(1, 1),
  },
  btnStoreIcon: {
    fontSize: '40px !important'
  },
  btnStore: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography component="h4" variant="h4" align="center" color="textPrimary">
        Get the BunnyRecords App
      </Typography>
      <div className={classes.cardAction}>
         <Button
            href="https://apps.apple.com/in/app/bunnyrecords/id1501125197"
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<Apple className={classes.btnStoreIcon} />}
          >
            <div className={classes.btnStore}>
              <span className={classes.available}>Available on the </span>
              AppStore
            </div>
          </Button>
          <Button
            href="https://play.google.com/store/apps/details?id=com.bunnyrecords"
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<Android className={classes.btnStoreIcon} />}
          >
            <div className={classes.btnStore}>
              <span className={classes.available}>Available on the </span>
              Google Play
            </div>
          </Button>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
