import gql from 'graphql-tag';

const subPayment = gql`
  mutation subPayment(
    $priceId: String
    $plan: Int
    $mode: String
    $coupon: String
  ) {
    subPayment(
      plan: $plan
      priceId: $priceId
      mode: $mode
      coupon: $coupon
    ) {
      sessionId
    }
  }
`;
const subOrder = gql`
mutation subOrder(
  $sessionId: String
) {
  subOrder(
    sessionId: $sessionId
  ) {
    message
  }
}
`;
const updateProfile = gql`
  mutation updateProfile(
    $to: DateTime
    $from: DateTime
    $plan: Int
    $freetrial: Boolean
  ) {
    updateProfile(
      input: { to: $to, from: $from, plan: $plan, freetrial: $freetrial }
    ) {
      id
      name
      email
    }
  }
`;

const cancelStripeOrder = gql`
  mutation cancelStripeOrder(
    $id: String
  ) {
    cancelStripeOrder(
      id: $id
    ) {
      message
    }
  }
`;

const logout = gql`
  mutation logout {
    logout
  }
`;


export default { subPayment, updateProfile, subOrder, cancelStripeOrder, logout };
