import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  Typography
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircleTwoTone';
import Apple from '@material-ui/icons/Apple';
import Android from '@material-ui/icons/Android';
import mutations from './mutation.gql';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  heroContent: {
    padding: theme.spacing(2, 0, 6),
    textAlign: 'center'
  },
  successIcon: {
    fontSize: 50,
    color: '#4caf50'
  },
  available: {
    fontSize: '80%'
  }
}));

const PaymentSuccess = props => {

  const [subOrder, { loading }] = useMutation(mutations.subOrder);


  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
      _makeOrder(query.get('session_id'));
  }, [])

  const _makeOrder = async (sessionId) => {
    await subOrder({
      variables: {
        sessionId
      }
    })
  }
  const { className, ...rest } = props;

  const classes = useStyles();


  return (
    <div className={classes.root}>
    <Card
      {...rest}
    >
        <CardContent>
          <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <CheckCircle className={classes.successIcon} />
            <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
              Your Payment is Successfull
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
            Thanks for subscribing to BunnyRecords. Enjoy complete access to all the features in mobile app. Happy records keeping!
            </Typography>
          </Container>
        </CardContent>
    </Card>
    </div>
  );
};

PaymentSuccess.propTypes = {
  className: PropTypes.string
};

export default PaymentSuccess;
