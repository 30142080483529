import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import mutations from '../../../../views/Pricing/mutation.gql';
import { USER_ACCESS_TOKEN, USER_LOGIN_TIME } from 'constants/auth';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      left: 240,
      width: 'calc(100% - 240px)'
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const [logout] = useMutation(mutations.logout);

  const classes = useStyles();

  const [notifications] = useState([]);
  const _logout = async () => {
     await logout();
     localStorage.removeItem(USER_ACCESS_TOKEN);
     localStorage.removeItem(USER_LOGIN_TIME);
     window.location.reload();
  }
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
      <IconButton
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        <div className={classes.flexGrow} />
        <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={_logout}
          >
            <InputIcon />
          </IconButton>

      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
