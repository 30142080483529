import gql from 'graphql-tag';

const user = gql`
  query getUser {
    user {
      id
      name
      email
      plan
      from
      to
      freetrial
      lifetime
      newsletter
    }
  }
`;
const getStripeOrder = gql`
  query getStripeOrder {
    getStripeOrder {
      status
      sub
    }
  }
`;
export default { getStripeOrder, user };
