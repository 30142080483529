import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Snackbar } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Link,
  Typography
} from '@material-ui/core';
import { InputText } from 'components/Form';
import { USER_ACCESS_TOKEN, USER_LOGIN_TIME } from 'constants/auth';
import LoginSchema from './LoginSchema';
import mutations from './mutation.gql';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(2),
    fontWeight: '500'
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  forgotLink: {
    marginTop: theme.spacing(1),
    textAlign: 'right',
  }
}));

const Login = props => {
  const { history } = props;

  const [open, setOpen] = React.useState(false);

  const [login, { loading }] = useMutation(mutations.login);
  const classes = useStyles();


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // After submit
  const _login = async values => {
    const { email, password } = values;
    try {
      const variables = {
        email,
        password,
        ref: true
      };
      const { data } = await login({
        variables,
      });
      // Get token
      const { token } = data.login;
      // Set Token
      localStorage.setItem(USER_ACCESS_TOKEN, token);

      // Set Login Time
      localStorage.setItem(
        USER_LOGIN_TIME,
        moment
          .utc()
          .add(2, 'hours')
          .format(),
      );

      // Push to dashboard
      history.push('/');

    } catch (err) {
      setOpen(err.graphQLErrors.length > 0
        ? err.graphQLErrors[0].message
        : err.networkError.result.errors[0].message)
    }
  };
  console.log(props)
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity="error"
        >
          {open}
        </Alert>
      </Snackbar>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <img
                alt="Logo"
                height="80"
                src="/images/logo.png"
              />
              <Typography
                className={classes.title}
                variant="h3"
              >
                Sign in to BunnyRecords
              </Typography>
            </div>
            <div className={classes.contentBody}>
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={values => _login(values)}
                validationSchema={LoginSchema}
              >
                {({ handleSubmit, dirty, isSubmitting }) => (
                  <div className={classes.form}>
                    <Field
                      component={InputText}
                      label="Email"
                      name="email"
                    />
                    <Field
                      component={InputText}
                      label="Password"
                      name="password"
                      type="password"
                    />
                    <Typography
                      className={classes.forgotLink}
                      color="textSecondary"
                      variant="body1"
                    >
                      <Link
                        component={RouterLink}
                        to="/reset"
                        variant="h6"
                      >
                         Forgot your password?
                      </Link>
                    </Typography>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!dirty || isSubmitting}
                      fullWidth
                      onClick={handleSubmit}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                       Sign In
                    </Button>
                    {
                    /*
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                        New to BunnyRecords? &nbsp;
                      <Link
                        component={RouterLink}
                        to="/join"
                        variant="h6"
                      >
                         Create an account.
                      </Link>
                    </Typography>
                    */
}
                  </div>
                )}

              </Formik>

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object
};

export default withRouter(Login);
