import React from 'react';
import moment from 'moment';
import { USER_ACCESS_TOKEN, USER_LOGIN_TIME } from 'constants/auth';

export default function noRequireAuth(WrappedComponent) {
  class NoRequireAuth extends React.Component {
    componentDidMount() {
      const user = localStorage.getItem(USER_ACCESS_TOKEN);
      const loginTime = localStorage.getItem(USER_LOGIN_TIME);
      const { history } = this.props;
      if (user && loginTime && !moment.utc().isAfter(moment.utc(loginTime).format())) {
        console.log('true')
        history.push('/');
      } else {
        localStorage.clear();
      }
    }
    componentDidUpdate() {
      // Logout user if session expired
      const loginTime = localStorage.getItem(USER_LOGIN_TIME);
      if (!loginTime || moment.utc().isAfter(moment.utc(loginTime).format())) {
        localStorage.clear();
        window.location.reload();
      }
    }
    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return NoRequireAuth;
}
