import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
// stripe
import { loadStripe } from '@stripe/stripe-js';
import StarIcon from '@material-ui/icons/StarBorder';
import { Grid, Card, CardHeader, CardContent, Divider, Typography, Button, CardActions, Container, TextField, Snackbar } from '@material-ui/core';
import { tiers } from '../../constants/data';
import mutations from './mutation.gql';
import queries from './query.gql';
import Alert from 'components/Alert';
import moment from 'moment';

const stripePromise = loadStripe('pk_live_0lUxJFbciTyvApsEO3SOxtEZ');

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  root: {
    [theme.breakpoints.up('md')]: {
       padding: theme.spacing(4)
    },
    padding: theme.spacing(0)
  },
  heroContent: {
    padding: theme.spacing(2, 2, 2),
    textAlign: 'center'
  },
  logo: {
     marginBottom: theme.spacing(1)
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    left: 'calc(50% - 400px)',
    top: '40%',
    transform: 'translate(50%, 0%)',
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  profileCard: {
    marginTop: theme.spacing(3),
  },
  plan: {
    marginTop: theme.spacing(2)
  },
  offerPrice: {
    textDecoration: 'line-through',
    marginLeft: theme.spacing(0.5),
  },
  discount: {
    textAlign: "right",
    display: 'inline',
    float: 'right',
    padding: theme.spacing(0.2, 2),
    background: 'green',
    color:'#ffffff',
    fontSize: '12px'
  }
}));

const Pricing = (props) => {

  const [open, setOpen] = React.useState(false);

  const [coupon, setCoupon] = React.useState('');

  const [subPayment, { loading }] = useMutation(mutations.subPayment);

  const [cancelStripeOrder, { loading: cancelLoading }] = useMutation(mutations.cancelStripeOrder);

  const { data: { getStripeOrder } = { getStripeOrder: {}}, refetch } = useQuery(queries.getStripeOrder);

  const { data: { user } = { user: {} } } = useQuery(queries.user);

  const classes = useStyles();

  const _cancelOrder = async (id) => {
      try {
        await cancelStripeOrder({
          variables: {
            id
          }
        })
        refetch();
        setOpen({
          type: 'success',
          payload: `Your subscription has been canceled. You can continue using your current paid plan until ${moment(user.to).format('MMM DD, YYYY')}`
        })
      } catch(err) {
        setOpen({
          type: 'error',
          payload: err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].message
          : err.networkError.result.errors[0].message
        })
      }
  }
  const handleOpen = async (priceId, plan) => {
    try {
      const variables = {
        priceId,
        plan,
        mode: plan == 3 ? 'payment' : 'subscription',
      }
      console.log(variables);
      if(coupon != '' && plan != 3) {
        variables.coupon = coupon;
      }
      // Call your backend to create the Checkout session.
      const checkoutSession = await subPayment({
        variables
      });
      console.log(checkoutSession);
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.data.subPayment.sessionId,
      });
    } catch(err) {
      setOpen({
        type: 'error',
        payload: err.graphQLErrors.length > 0
        ? err.graphQLErrors[0].message
        : err.networkError.result.errors[0].message
      })
    }
    // props.history.push(`/subscription?plan=${index}`);
  };

  const handleCoupon = (e) => {
    setCoupon(e.target.value)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  /*
  if(error) {
    console.log(error.graphQLErrors.length > 0
            ? error.graphQLErrors[0].message
            : error.networkError.result.errors[0].message)
  }*/
  console.log(getStripeOrder);
  const _getPlan = (plan, lifetime) => {
    let planName;
    if(plan == 1) {
      planName = 'LITTLE BUNNY'
    } else if(plan == 2) {
      planName = 'GOLDEN BUNNY'
    } else {
      planName = 'FREE BUNNY'
    }

    return (
      <div className={classes.plan}>
          <Typography variant="body1">YOUR PLAN: <b>{lifetime ? 'FOREVER BUNNY' : planName}</b> </Typography>
          {
            !lifetime && plan != 3 &&
                  <Typography
                    className={classes.locationText}
                    color="textSecondary"
                    variant="body1"
                  >
                    {
                      moment(moment()).isAfter(moment(user.to)) ?
                      `Your subscription has been expired.`
                      :
                      `Current plan until ${moment(user.to).format('MMM DD, YYYY')}`
                    }
                  </Typography>
          }
      </div>
    )
  }
  return (
    <div className={classes.root}>
     <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        onClose={handleClose}
        open={open.type ? true : false}
      >
        <Alert
          onClose={handleClose}
          severity={open.type && open.type}
        >
          {open.payload && open.payload}
        </Alert>
      </Snackbar>
      <Card>
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <img
              alt="Logo"
              height="70"
              src="/images/logo.png"
              className={classes.logo}
            />
            <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
              Pricing Plans
            </Typography>
            <Typography align="center" color="textSecondary" component="h5">
               This website is purely for making payments and information about BunnyRecords. All features can be used by mobile app only.
            </Typography>
          </Container>
        <Divider />
         <CardContent>
            <Grid
              container
              spacing={4}
            >
              {tiers.map((tier, index) => (
                  // Enterprise card is full width at sm breakpoint
                  <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                    <Card>
                      <CardHeader
                        title={tier.title}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: 'center' }}
                        subheaderTypographyProps={{ align: 'center' }}
                        action={tier.title === 'GOLDEN BUNNY' ? <StarIcon /> : null}
                        className={classes.cardHeader}
                      />
                      <CardContent>
                        {
                           tier.duration == '' &&
                          <Typography component="h6" variant="h6" className={classes.discount} color="textPrimary">
                              20% OFFER
                          </Typography>
                        }
                        <div className={classes.cardPricing}>
                          <Typography component="h2" variant="h3" color="textPrimary">
                            ${tier.price}
                          </Typography>
                          {
                            tier.duration == '' && <Typography className={classes.offerPrice} variant="h4" color="textSecondary">
                            $249
                          </Typography>
                          }
                          <Typography variant="h6" color="textSecondary">
                            {tier.duration}
                          </Typography>
                        </div>
                        <ul>
                          {tier.description.map((line) => (
                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                              {line}
                            </Typography>
                          ))}
                        </ul>
                      </CardContent>
                      <CardActions>
                        {
                          index !== 2 &&
                                          <TextField
                                            fullWidth
                                            label="Coupon"
                                            margin="dense"
                                            name="coupon"
                                            onChange={handleCoupon}
                                            value={coupon}
                                            variant="outlined"
                                          />
                        }
                        <Button onClick={() => handleOpen(tier.id, index + 1)} fullWidth variant={tier.buttonVariant} color="primary">
                          {tier.buttonText}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          lg={6}
          md={6}
          xl={6}
          xs={12}
        >
      <Card className={classes.profileCard}>
      <CardHeader title="My Account" />
      <Divider />
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography
              gutterBottom
              variant="h5"
            >
              {user.name}
            </Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              {user.email}
            </Typography>
          </div>
        </div>
        {
          user.plan && _getPlan(user.plan, user.lifetime)
        }
      </CardContent>
      <Divider />

      {
        getStripeOrder.status && getStripeOrder.status == 'active' &&
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="secondary"
            variant="text"
            onClick={() => { _cancelOrder(getStripeOrder.sub) }}
          >
            Cancel Subscription
          </Button>
        </CardActions>
      }
      {
        getStripeOrder.status && getStripeOrder.status == 'canceled' &&
        <CardActions>
           <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1"
            >
              Your subscription has been canceled. You can continue using your current paid plan until {moment(user.to).format('MMM DD, YYYY')}
            </Typography>
        </CardActions>
      }
    </Card>
    </Grid>
    </Grid>
    </div>
  );
};

export default Pricing;
