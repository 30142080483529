import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpLink } from 'apollo-link-http';
import moment from 'moment';
import { USER_ACCESS_TOKEN, USER_LOGIN_TIME } from 'constants/auth';

// const URI = 'https://bunnyapi.herokuapp.com/graphql';
const URI = 'https://bunnyapi.herokuapp.com/graphql';
const setupApolloClient = async token => {
  // create an apollo link instance, a network interface for apollo client
  const headers = {};
  const authMiddleware = setContext(
    (_, { headers }) =>
      new Promise(async resolve => {
        const auth = {};
        // get the authentication token from local storage if it exists
        const token = localStorage.getItem(USER_ACCESS_TOKEN);
        const loginTime = localStorage.getItem(USER_LOGIN_TIME);
        // To check token verify
        const isTokenExpire = moment.utc().isAfter(
          moment
            .utc(loginTime)
            .format(),
        );
        if(isTokenExpire) {
          localStorage.removeItem(USER_LOGIN_TIME);
          localStorage.removeItem(USER_ACCESS_TOKEN);
          window.location.reload();
        }
        if (token) {
          auth.Authorization = `Bearer ${token}`;
        }
        resolve({
          headers: {
            ...headers,
            ...auth,
          },
        });
      }),
  );

  const httpLink = new HttpLink({
    uri: URI
  });

  const link = authMiddleware.concat(httpLink);
  // create an inmemory cache instance for caching graphql data
  const cache = new InMemoryCache();
  // instantiate apollo client with apollo link instance and cache instance
  const client = new ApolloClient({
    link,
    cache,
  });
  return client;
};
export default setupApolloClient;
