import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, colors } from '@material-ui/core';
import Apple from '@material-ui/icons/Apple';
import Android from '@material-ui/icons/Android';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.grey[50]
  },
  media: {
    paddingTop: theme.spacing(2),
    height: 80,
    textAlign: 'center',
    '& > img': {
      height: '100%',
      width: 'auto'
    }
  },
  content: {
    padding: theme.spacing(1, 2)
  },
  available: {
    fontSize: '80%'
  },
  actions: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    background: '#1e4355 !important',
    color: '#ffffff !important',
    margin: theme.spacing(1, 1),
    fontSize: '12px'
  },
  btnStoreIcon: {
    fontSize: '40px !important'
  },
  btnStore: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
    flexDirection: 'column'
  }
}));

const UpgradePlan = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
    >
      <div className={classes.cardAction}>
         <Button
            href="https://apps.apple.com/in/app/bunnyrecords/id1501125197"
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<Apple className={classes.btnStoreIcon} />}
          >
            <div className={classes.btnStore}>
              <span className={classes.available}>Available on the </span>
              AppStore
            </div>
          </Button>
          <Button
            href="https://play.google.com/store/apps/details?id=com.bunnyrecords"
            variant="contained"
            size="large"
            className={classes.button}
            startIcon={<Android className={classes.btnStoreIcon} />}
          >
            <div className={classes.btnStore}>
              <span className={classes.available}>Available on the </span>
              Google Play
            </div>
          </Button>
      </div>
    </div>
  );
};

UpgradePlan.propTypes = {
  className: PropTypes.string
};

export default UpgradePlan;
