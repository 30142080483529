import React from 'react';
import moment from 'moment';
import { USER_LOGIN_TIME, USER_ACCESS_TOKEN } from 'constants/auth';

export default function requireAuth(WrappedComponent) {
  class RequireAuth extends React.Component {

    componentDidMount() {
      const user = localStorage.getItem(USER_ACCESS_TOKEN);
      const loginTime = localStorage.getItem(USER_LOGIN_TIME);
      const { history } = this.props;
      if (!user || !loginTime || moment.utc().isAfter(moment.utc(loginTime).format())) {
        localStorage.clear();
        history.push('/login');
      }
    }
    componentDidUpdate() {
      // Logout user if session expired
      const loginTime = localStorage.getItem(USER_LOGIN_TIME);
      if (!loginTime || moment.utc().isAfter(moment.utc(loginTime).format())) {
        localStorage.clear();
        window.location.reload();
      }
    }
    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return RequireAuth;
}
