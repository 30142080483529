import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  password: Yup.string().required("Password is Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required")
});

export default LoginSchema;
