import gql from 'graphql-tag';

const signup = gql`
  mutation signup($name: String!, $email: String!, $password: String!) {
    signup(input: { email: $email, password: $password, name: $name }) {
      token
    }
  }
`;

export default { signup };
