import * as Yup from 'yup';

const SignUpSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  password: Yup.string()
    .min(6, "Password should be minium 6 character")
    .required("Password is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
});

export default SignUpSchema;
