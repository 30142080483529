import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Auth as AuthLayout } from './layouts';

import {
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  Login as LoginView,
  Reset as ResetView,
  Password as PasswordView,
  NotFound as NotFoundView,
  Pricing as PricingView,
  PaymentSuccess as PaymentSuccessView,
  PaymentFailure as PaymentFailureView
} from './views';
import requireAuth from 'components/Auth/requireAuth';
import noRequireAuth from 'components/Auth/noRequireAuth';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/pricing"
      />
      <RouteWithLayout
        component={requireAuth(PricingView)}
        exact
        layout={MainLayout}
        path="/pricing"
      />
      <RouteWithLayout
        component={requireAuth(PaymentSuccessView)}
        exact
        layout={MainLayout}
        path="/subscription/success"
      />
      <RouteWithLayout
        component={requireAuth(PaymentFailureView)}
        exact
        layout={MainLayout}
        path="/subscription/cancel"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={noRequireAuth(SignUpView)}
        exact
        layout={AuthLayout}
        path="/join"
      />
      <RouteWithLayout
        component={noRequireAuth(LoginView)}
        exact
        layout={AuthLayout}
        path="/login"
      />
      <RouteWithLayout
        component={noRequireAuth(ResetView)}
        exact
        layout={AuthLayout}
        path="/reset"
      />
       <RouteWithLayout
        component={PasswordView}
        exact
        layout={AuthLayout}
        path="/reset-password/:hash"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
