import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import setupApolloClient from './config/setup';
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { USER_ACCESS_TOKEN } from 'constants/auth';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends React.Component {
  state = {
    isLoadingComplete: false,
    client: null,
    auth: false,
  };
  componentDidMount() {
    this._loadResourcesAsync();
  }
  _loadResourcesAsync = async () => {
    const user = localStorage.getItem(USER_ACCESS_TOKEN);
    const client = await setupApolloClient();
    this.setState({
      client,
      auth: user ? true : false,
    });
  };
  render() {
    const { client } = this.state;
    if (!client) {
      return <div>Loading....</div>;
    } else {
      return (
        <ThemeProvider theme={theme}>
          <ApolloProvider client={this.state.client}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ApolloProvider>
        </ThemeProvider>
      );
    }
  }
}
