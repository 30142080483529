import gql from 'graphql-tag';

const reset = gql`
  mutation reset($hash: String!, $password: String!) {
    reset(input: {
        hash: $hash
        password: $password
    }) {
      message
    }
  }
`;


export default { reset };
