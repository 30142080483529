import gql from 'graphql-tag';

const forgot = gql`
  mutation forgot($email: String!) {
    forgot(email: $email) {
      message
    }
  }
`;

export default { forgot };
