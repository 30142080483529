import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  Typography
} from '@material-ui/core';
import Error from '@material-ui/icons/ErrorTwoTone';
import Apple from '@material-ui/icons/Apple';
import Android from '@material-ui/icons/Android';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  heroContent: {
    padding: theme.spacing(2, 0, 6),
    textAlign: 'center'
  },
  successIcon: {
    fontSize: 50,
    color: 'red'
  },
  available: {
    fontSize: '80%'
  }
}));

const PaymentSuccess = props => {
  const { className, ...rest } = props;

  const classes = useStyles();


  return (
    <div className={classes.root}>
    <Card
      {...rest}
    >
        <CardContent>
          <Container maxWidth="sm" component="main" className={classes.heroContent}>
            <Error className={classes.successIcon} />
            <Typography component="h2" variant="h2" align="center" color="textPrimary" gutterBottom>
              Oops! Payment  not sucessfull.
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" component="p">
              Try again or contact support@bunnyrecords.com
            </Typography>
          </Container>
        </CardContent>
        <Divider />
    </Card>
    </div>
  );
};

PaymentSuccess.propTypes = {
  className: PropTypes.string
};

export default PaymentSuccess;
