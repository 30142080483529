/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  FormControl, FormHelperText, MenuItem, InputLabel, Select
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(2),
    background: '#ffffff'
  },
  formControl: {
    marginTop: theme.spacing(2),
    background: '#ffffff',
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const InputSelect = props => {
  const [ selected, setSelVal] = React.useState('');
  const { form, field, label, options, none } = props;
  const classes = useStyles();
  const { isSubmitting, errors, touched, setFieldValue } = form;
  const { name, value } = field;
  const handleChange = (event) => {
    setSelVal(event.target.value)
    setFieldValue(
      name,
      event.target.value
    );
  };
  return (
    <FormControl  margin="dense" variant="outlined" className={classes.formControl} error={!!(errors[name] && touched[name])}>
        <InputLabel id="demo-simple-select-outlined-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          margin="dense"
          id="demo-simple-select-outlined"
          value={value ? value : selected}
          onChange={handleChange}
          label={label}
        >
          {
            none && <MenuItem value=""><em>None</em> </MenuItem>
          }
          {
            options.map(opt => {
            return <MenuItem key={opt.id} value={opt.id}>{opt.name}</MenuItem>
            })
          }
        </Select>
        {
          errors[name] && touched[name] && <FormHelperText>{errors[name]}</FormHelperText>
        }
    </FormControl>
  );
};

export default InputSelect;
