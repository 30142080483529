import gql from 'graphql-tag';

const login = gql`
  mutation login($email: String!, $password: String!, $ref: Boolean) {
    login(input: { email: $email, password: $password }, ref: $ref) {
      id
      token
    }
  }
`;

export default { login };
