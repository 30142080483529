import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { Snackbar } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Link,
  Typography
} from '@material-ui/core';
import { InputText } from 'components/Form';
import ResetSchema from './ResetSchema';
import mutations from './mutation.gql';
import Alert from 'components/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  title: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  backLogin: {
    textAlign: 'center',
  }
}));

const Reset = props => {

  const [open, setOpen] = React.useState(false);

  const [forgot] = useMutation(mutations.forgot);
  const classes = useStyles();


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // After submit
  const _forgot = async values => {
    const { email } = values;
    try {
      const variables = {
        email,
      };
      const { data } = await forgot({
        variables,
      });
      setOpen({
        type: 'success',
        payload: data.forgot.message,
      });
    } catch (err) {
      // console.log(err.networkError.result.errors);
      setOpen({
        type: 'error',
        payload: err.graphQLErrors.length > 0
          ? err.graphQLErrors[0].message
          : 'Something went wrong.',
      });
    }
  };
  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity={open.type && open.type}
        >
          {open.payload && open.payload}
        </Alert>
      </Snackbar>
      <Grid
        className={classes.grid}
        container
      >
        <Grid
          className={classes.content}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <img
                alt="Logo"
                height="80"
                src="/images/logo.png"
              />
              <Typography
                className={classes.title}
                variant="h3"
              >
                Reset Your Password
              </Typography>
            </div>
            <div className={classes.contentBody}>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={values => _forgot(values)}
                validationSchema={ResetSchema}
              >
                {({ handleSubmit, dirty, isSubmitting }) => (
                  <div className={classes.form}>
                    <Field
                      component={InputText}
                      label="Email"
                      name="email"
                    />

                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!dirty || isSubmitting}
                      fullWidth
                      onClick={handleSubmit}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                        Send reset instructions
                    </Button>
                    <Typography
                      className={classes.backLogin}
                      color="textSecondary"
                      variant="body1"
                    >
                      <Link
                        component={RouterLink}
                        to="/login"
                        variant="h6"
                      >
                          Back to Login
                      </Link>
                    </Typography>
                  </div>
                )}

              </Formik>

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Reset.propTypes = {
  history: PropTypes.object
};

export default withRouter(Reset);
